import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router'
import { NavLink } from 'react-router-dom'
import AppStore from '@/store/app'
import Mq from '@/store/mq'
import Logo from '@/components/Logo'
import SearchGlyph from '@/components/SearchInput/Glyph'
import withCraftEntries from '@/components/withCraftEntries'

function MobileNavToggleButton(props) {
	return (
		<button {...props} className={styles.navToggle}>
			<svg
				width="34px"
				height="30px"
				viewBox="0 0 34 30"
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				xmlnsXlink="http://www.w3.org/1999/xlink"
			>
				<defs>
					<path
						d="M1.5625,5.796875 L32.4375,5.796875 C33.0933711,5.796875 33.625,5.26524609 33.625,4.609375 L33.625,1.640625 C33.625,0.984753906 33.0933711,0.453125 32.4375,0.453125 L1.5625,0.453125 C0.906628906,0.453125 0.375,0.984753906 0.375,1.640625 L0.375,4.609375 C0.375,5.26524609 0.906628906,5.796875 1.5625,5.796875 Z M1.5625,17.671875 L32.4375,17.671875 C33.0933711,17.671875 33.625,17.1402461 33.625,16.484375 L33.625,13.515625 C33.625,12.8597539 33.0933711,12.328125 32.4375,12.328125 L1.5625,12.328125 C0.906628906,12.328125 0.375,12.8597539 0.375,13.515625 L0.375,16.484375 C0.375,17.1402461 0.906628906,17.671875 1.5625,17.671875 Z M1.5625,29.546875 L32.4375,29.546875 C33.0933711,29.546875 33.625,29.0152461 33.625,28.359375 L33.625,25.390625 C33.625,24.7347539 33.0933711,24.203125 32.4375,24.203125 L1.5625,24.203125 C0.906628906,24.203125 0.375,24.7347539 0.375,25.390625 L0.375,28.359375 C0.375,29.0152461 0.906628906,29.546875 1.5625,29.546875 Z"
						id="mobilenavbars"
					></path>
				</defs>
				<g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
					<g id="Our-Firm" transform="translate(-272.000000, -19.000000)">
						<g id="mobile-header" transform="translate(14.000000, 14.000000)">
							<g id="Solid/bars" transform="translate(258.000000, 5.000000)">
								<mask id="mask-2" fill="white">
									<use xlinkHref="#mobilenavbars"></use>
								</mask>
								<use id="Mask" fillRule="nonzero" xlinkHref="#mobilenavbars"></use>
							</g>
						</g>
					</g>
				</g>
			</svg>
		</button>
	)
}

function NavListItem({ href, ...props }) {
	return href ? (
		<a href={href} target="_blank">
			{props.children}
		</a>
	) : (
		<NavLink activeClassName={styles.active} {...props} />
	)
}

@observer
class Navigation extends React.Component {
	static defaultProps = {
		categories: []
	}

	static propTypes = {
		categories: PropTypes.array,
		location: PropTypes.shape({
			pathname: PropTypes.string
		})
	}

	toggleMobileNav() {
		AppStore.showOverlayNav()
	}

	get navItems() {
		return [
			{ path: '/our-firm', title: 'Our Firm' },
			{ path: '/our-team', title: 'Our Team' },
			{ path: '/practices', title: 'Practices' },
			{ path: '/locations', title: 'Locations' },
			{
				path: '/news-insights',
				title: 'News & Insights',
				children: this.props.categories.map((category) => ({
					href: category.redirect,
					path: `/news-insights/?category=${category.slug}`,
					title: category.title
				}))
			},
			{ path: '/careers', title: 'Careers' }
		]
	}

	get linkedSearchGlyph() {
		return (
			<NavLink to="/search">
				<SearchGlyph className={styles.searchGlyph} />
			</NavLink>
		)
	}

	render() {
		const useExpandingNav = !Mq.desktop
		return (
			<nav className={styles.nav}>
				<div className={styles.navUpper}>
					<Logo render={<img src={require('./ropers_75yr.png')} alt="Ropers Majeski - 75 Years Strong" />} />
					<ul className={styles.navList}>
						{!useExpandingNav && (
							<>
								{this.navItems.map((item) => (
									<li className={styles.primary} key={item.path}>
										<NavListItem to={item.path}>{item.title}</NavListItem>
										{item.children && (
											<ul>
												{item.children.map((child) => (
													<li className={styles.secondary} key={child.path}>
														<NavListItem href={child.href} to={child.path}>
															{child.title}
														</NavListItem>
													</li>
												))}
											</ul>
										)}
									</li>
								))}
							</>
						)}
						<li className={styles.primary}>{this.linkedSearchGlyph}</li>
						{useExpandingNav && (
							<li className={styles.primary}>
								<MobileNavToggleButton onClick={::this.toggleMobileNav} />
							</li>
						)}
					</ul>
				</div>
			</nav>
		)
	}
}

export default withCraftEntries(withRouter(Navigation), {
	categories: {
		query: require('@/craft/queries/articleCategories.gql')
	}
})
